import React from "react";
import { Link } from "react-router-dom";
import heroImage from "../../images/8.png";
import Stars from "../../images/stars.png";
import { motion } from "framer-motion";

const Herosection = () => {
  return (
    <div className="font-poppins overflow-x-hidden">
      {/* component from tailgrids */}

      <div className="relative  pt-[120px] pb-[110px] lg:pt-[150px] bg-[#e0e0e0]">
        <div className="container mx-auto px-10">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <motion.div
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 75 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <h1 className="text-transparent bg-clip-text bg-gradient-to-r sm:text-8xl text-2xl bg-gradient-to-t from-yellow-400 to-blue-900  mb-3  font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[42px]">
                    Here you can
                    <br />
                    <span className="sm:text-7xl text-5xl">
                      Level Up Your Productivity
                    </span>
                  </h1>
                  <p className="text-body-color text-sm mb-8 max-w-[480px] sm:text-base">
                    Welcome, and thank you for taking the time to explore
                    Accuchrono, the time-tracking application! We’re excited to
                    help elevate your business to new heights. So, stay tuned,
                    and let’s Level UP together!
                  </p>
                </motion.div>
                <motion.div
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -75 },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  <ul className="flex flex-wrap items-center">
                    <li>
                      <Link
                        to="#"
                        className="bg-yellow-400 inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-base font-normal text-darkblue hover:bg-yellow-500 sm:px-10 lg:px-8 xl:px-10"
                      >
                        Get a Demo
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="bg-yellow-200/80 ml-6 inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-darkblue font-normal hover:bg-yellow-200 sm:px-10 lg:px-8 xl:px-10"
                      >
                        Talk to Us
                      </Link>
                    </li>
                  </ul>
                </motion.div>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <motion.div
                  variants={{
                    visible: { opacity: 1, x: 0, filter: "blur(0px)" },
                    hidden: { opacity: 0, x: 75, filter: "blur(5px)" },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                  className="relative z-10 inline-block pt-11 lg:pt-0 mt-5 bottom-14"
                >
                  <img
                    src={heroImage}
                    alt="hero"
                    className="max-w-full lg:ml-auto rounded-l-[20%] rounded-br-[6%]"
                  />
                  <img
                    src={Stars}
                    alt="stars"
                    className="z-20 w-28 absolute mt-[-60%] right-[-5%] opacity-70 hover:opacity-100"
                  />
                  <span className="absolute -left-8 -bottom-3 z-[-1]">
                    <svg
                      width="93"
                      height="93"
                      viewBox="0 0 93 93"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                    </svg>
                  </span>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* component from tailgrids end */}
    </div>
  );
};

export default Herosection;
