import React, { useEffect, useRef } from "react";
import Card from "./Card";
import image1 from "../../images/Price/1.png";
import image2 from "../../images/Price/2.png";
import image3 from "../../images/Price/3.png";
import { useAnimation, useInView, motion } from "framer-motion";

const CardComponent = () => {
  const { innerWidth: Width } = window;
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControlls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControlls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="max-w-screen overflow-x-hidden font-poppins">
      <div className="w-full py-[5rem] px-4 bg-white -mt-28">
        <div className="flex justify-center">
          <h2 className="text-3xl font-bold text-center sm:text-3xl mt-[6%] text-black">
            Pricing Made Simple
          </h2>
        </div>
        <p className="flex text-xl justify-center sm:mb-24 mb-16 text-center mt-6 sm:mt-0 ">
          What You See Is What You Pay
        </p>
        {/* cards */}
        <>
          {Width < 631 ? (
            <>
              <div className="max-w-[1240px] mx-auto grid sm:grid-cols-3 gap-8">
                <div>
                  <Card
                    image={image2}
                    Package={"  Permanently free"}
                    Price={"$0"}
                    Feature1={"Unlimited users"}
                    Feature2={"Unlimited tracking"}
                    Feature3={"Unlimited timesheet"}
                    iconColor1={"text-yellow-500"}
                    iconColor2={"text-yellow-500"}
                    iconColor3={"text-yellow-500"}
                    buttonBg={"bg-yellow-400"}
                    hoverBgButton={"hover:bg-yellow-500"}
                    buttonTextColor={"text-white"}
                    standOutBg={"bg-blue-100/30"}
                    marginMiddle={"md:my-6"}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="max-w-[1240px] mx-auto grid sm:grid-cols-3 gap-8">
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControlls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                ></motion.div>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControlls}
                  transition={{ duration: 0.5, delay: 0.65 }}
                >
                  <Card
                    image={image2}
                    Package={"  Permanently free"}
                    Price={"$0"}
                    Feature1={"Unlimited users"}
                    Feature2={"Unlimited tracking"}
                    Feature3={"Unlimited timesheet"}
                    iconColor1={"text-yellow-500"}
                    iconColor2={"text-yellow-500"}
                    iconColor3={"text-yellow-500"}
                    buttonBg={"bg-yellow-400"}
                    hoverBgButton={"hover:bg-yellow-500"}
                    buttonTextColor={"text-white"}
                    standOutBg={"bg-blue-100/30"}
                    marginMiddle={"md:my-6"}
                  />
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControlls}
                  transition={{ duration: 0.5, delay: 0.25 }}
                ></motion.div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default CardComponent;
