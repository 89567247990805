import React, { useState } from "react";
import Icon from "../../images/logo.png";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import profileImage from "../../images/profile.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="max-w-screen overflow-x-hidden font-poppins">
      <nav className="bg-darkblue fixed px-2  sm:h-auto sm:w-full w-screen z-20 left-0 right-0 top-0 border-b border-gray-400">
        {/* dektop nav */}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto sm:p-4 pb-2">
          <Link to="/" className="flex items-center">
            <img
              src={Icon}
              alt="icon"
              className="hidden sm:block sm:w-[60px] sm:-h-[60px] "
            />
          </Link>
          <div className="flex">
            <ul className="sm:flex justify-center hidden">
              <li className="p-2 mr-4 text-white hover:text-blue-400 cursor-pointer">
                <Link to="/">Home</Link>
              </li>
              {/* <li className="p-2 mr-4 text-white hover:text-blue-400 cursor-pointer">
                <Link to="/services">Features</Link>
              </li> */}

              {/* <li className="p-2 mr-4 text-white hover:text-blue-400 cursor-pointer">
                <Link to="/about">Download</Link>
              </li> */}
              <li className="p-2 mr-4 text-white hover:text-blue-400 cursor-pointer">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="flex">
            <button
              type="button"
              className="bg-darkyellow px-4 py-2 text-darkblue hidden sm:block rounded-xl mr-2 hover:bg-yellow-600"
            >
              Get a Demo
            </button>

            <button onClick={() => setOpen(!open)} className="sm:hidden block">
              <GiHamburgerMenu size={30} className="mt-2 mr-2 text-white/70" />
            </button>
          </div>
        </div>

        {/* desktop nav end */}
        <div className={`${open ? null : "hidden"} h-[19rem]`}>
          <ul>
            <li className="text-white hover:text-blue-400 font-semibold pl-8 cursor-pointer pb-4 pt-6">
              <Link to="/">Home</Link>
            </li>

            {/* <li className="text-white hover:text-blue-400 font-semibold pl-8 cursor-pointer pb-4">
              <Link to="/services">Features</Link>
            </li> */}
            {/* <li className="text-white hover:text-blue-400 font-semibold pl-8 cursor-pointer pb-4">
              <Link to="/about">Download</Link>
            </li> */}
            <li className="text-white hover:text-blue-400 font-semibold pl-8 cursor-pointer pb-4">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          {/* profile section */}

          <div className="flex justify-center">
            <p className="mt-[25px] text-gray-100/30 text-sm">
              All Rights Reserved - Accuchrono
            </p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
